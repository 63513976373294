import React, { Component } from 'react'
import styled from "styled-components"
import { gsap } from "gsap";

import Layout from "../components/layout"
import Seo from "../components/seo"

import bg from "../images/bg--desktop.jpg"
import bgMobile from "../images/bg--mobile.jpg"
import video from "../images/bg--video.mp4"

const BGImage = styled.div`
    border-radius: 30px;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    @media screen and (min-width: 860px) {
        border-radius: 60px;
    }
`;

class IndexPage extends Component {
    state = {
        isActive: false
    };

    handleClick = () => {
        this.setState(state => ({ isActive: !state.isActive }));
    };

    componentDidMount() {
        const fadeElements = gsap.timeline()
        fadeElements.to('#main .bg-image', {
            opacity: 1,
            delay: 0.25,
        })
        fadeElements.to('#main .fade-up', {
            delay: 0.25,
            duration: 1,
            opacity: 1,
            stagger: {
                amount: '0.5',
            },
        })

        const contact = document.querySelector('.contact');
        const contactHeight = document.querySelector('.contact-height');

        var style = document.createElement('style');
        style.innerHTML =
            '.contact.active {' +
            'max-height: ' + (parseInt(contactHeight.clientHeight) + 40) + 'px' +
            '}';

        contact.parentNode.insertBefore(style, contact);
    }

    render() {
        return (
            <Layout>
                <div id="main" className="bg-white pl-2 pr-2 pt-2 pb-0 md:p-4 w-full flex flex-col">
                    <BGImage className="grow relative overflow-hidden bg-image">
                        <video autoPlay loop playsInline muted="true" controls='' className="video--fullscreen">
                            <source src={video} type="video/mp4" />
                        </video>
                    </BGImage>

                    <div className="grow-0">
                      <div className="md:flex h-full justify-between pt-3 md:pt-4 pb-4 overflow-hidden relative">
                          <div className="fade-up mb-2 md:mb-0">
                              <svg width="132" height="21" viewBox="0 0 132 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M3.94885 9.695L5.40744 0.209999V0H3.23735L0 9.695H3.94885ZM9.99664 9.695L11.4552 0.209999V0H9.28514L6.04779 9.695H9.99664Z" fill="#181818"/>
                                  <path d="M23.5161 11.97C26.4332 11.97 27.4293 13.055 27.4293 14.98C27.4293 16.765 26.2554 17.99 23.7295 17.99H18.1086V11.97H23.5161ZM23.0892 3.115C25.5794 3.115 26.5755 4.06 26.5755 6.055C26.5755 8.33 25.1525 9.17 22.9113 9.17H18.1086V3.115H23.0892ZM14.0531 21H23.8362C29.2437 21 31.4138 18.69 31.4138 15.295C31.4138 12.32 29.8485 10.99 26.1486 10.605V10.255C28.8524 9.625 30.4532 8.085 30.4532 5.355C30.4532 1.785 28.1409 0 23.018 0H14.0531V21Z" fill="#181818"/>
                                  <path d="M48.7831 21H53.1233L45.19 0H39.7114L31.7781 21H35.976L37.8259 15.645H46.9332L48.7831 21ZM39.7826 10.08C40.5297 7.945 41.3123 5.565 41.9527 3.465H42.842C43.4824 5.565 44.2295 7.945 44.9766 10.08L45.8659 12.67H38.8932L39.7826 10.08Z" fill="#181818"/>
                                  <path d="M67.9882 21H73.3245V0H69.5891V7.28C69.5891 10.045 69.7669 13.895 69.9804 16.87L69.34 16.905C68.3795 14.49 66.7786 10.955 65.2133 8.225L60.5174 0H54.7186V21H58.5252V13.79C58.5252 11.025 58.3473 6.615 58.1338 3.57L58.7742 3.5C59.8415 6.02 61.3356 9.31 62.5096 11.445L67.9882 21Z" fill="#181818"/>
                                  <path d="M75.621 3.675H82.2024V21H86.258V3.675H92.8394V0H75.621V3.675Z" fill="#181818"/>
                                  <path d="M95.1062 21H110.475V17.535H99.1618V12.075H109.443V8.645H99.1618V3.465H110.51V0H95.1062V21Z" fill="#181818"/>
                                  <path d="M122.608 3.36C125.668 3.36 126.77 4.34 126.77 6.475C126.77 8.715 125.312 9.765 122.537 9.765H117.983V3.36H122.608ZM127.268 21H132V20.615L126.059 12.705C129.047 11.83 130.755 9.66 130.755 6.37C130.755 2.205 128.229 0 122.893 0H113.928V21H117.983V13.125H121.968L127.268 21Z" fill="#181818"/>
                              </svg>
                          </div>
                          <div>
                              <div className="md:flex">
                                  <div className="text-base uppercase mb-1 md:mb-0 pr-2 lg:pr-8 border-ro fade-up">ban·ter - /ˈban(t)ər/ <span className="ml-1 md:hidden">(transitive verb)</span></div>
                                  <div className="text-base uppercase hidden md:block pl-2 lg:pl-4 pr-2 lg:pr-8 border-ro fade-up">(transitive verb)</div>
                                  <div className="md:pl-2 lg:pl-8">
                                      <div className="flex fade-up mb-1 md:mb-0">
                                          <div className="text-sm pt-px pr-1">001</div>
                                          <div className="font-serif text-sm md:text-md max-w-xs">To communicate the hard truth with wit and repartee, in the spirit of camaraderie</div>
                                      </div>
                                      <div className="flex md:pt-4 fade-up">
                                          <div className="text-sm pt-px pr-1">002</div>
                                          <div className="font-serif text-sm md:text-md max-w-xs">A conversation style that is direct, earnest, and non-combative</div>
                                      </div>
                                  </div>
                              </div>
                              <div className={`contact `+(this.state.isActive ? 'active' : '')}>
                                  <div className="contact-height">
                                      <div className="border-t md:border-t-0 mt-2 pt-1">
                                          <div className="text-sm md:text-base uppercase my-1">Contact</div>
                                          <div className="mb-1">
                                              <p className="font-serif text-sm md:text-md"><span>Media requests <a className="underline" href="mailto:tell@bantercap.com">tell@bantercap.com</a></span> <span className="ml-2">General banter <a className="underline" href="mailto:hello@bantercap.com">hello@bantercap.com</a></span></p>
                                          </div>
                                          <p className="font-serif text-sm md:text-md w-3/4 md:w-1/2">We are located in multiple timezones but are most frequently stationed in New York, Miami, Los Angeles, and San Francisco.</p>
                                      </div>
                                  </div>
                              </div>

                              <div className="text-right absolute bottom-0 right-0">
                                  <button className="text-sm inline-block uppercase underline pt-2 pb-2 md:pb-0 fade-up" onClick={this.handleClick}>{(this.state.isActive ? 'Close' : 'Contact')}</button>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
export default IndexPage

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Banter" />

